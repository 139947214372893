@import "//hello.myfonts.net/count/2fedd3";

@font-face {
  font-family: "icons";
  src: url("/typo3conf/ext/msi_skin/Resources/Fonts/icons.eot?0d3609129ecd18e7c4e8447203b35ec9");
  src: url("/typo3conf/ext/msi_skin/Resources/Fonts/icons.eot?#iefix") format("embedded-opentype"),url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/icons.woff?0d3609129ecd18e7c4e8447203b35ec9") format("woff"),url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/icons.ttf?0d3609129ecd18e7c4e8447203b35ec9") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.icon {
  font-family: "icons";
  width: 1em;
  height: 1em;
  margin-right: .25em;
  display: inline-block;
  box-sizing: border-box;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  speak: none;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-accessibility:before {
  content: "";
}

.icon-add-bold:before {
  content: "";
}

.icon-add-to-visit:before {
  content: "";
}

.icon-add:before {
  content: "";
}

.icon-adult:before {
  content: "";
}

.icon-anglebracket-down:before {
  content: "";
}

.icon-anglebracket-left:before {
  content: "";
}

.icon-anglebracket-right:before {
  content: "";
}

.icon-anglebracket-up:before {
  content: "";
}

.icon-arrow-down:before {
  content: "";
}

.icon-arrow-left:before {
  content: "";
}

.icon-arrow-right:before {
  content: "";
}

.icon-arrow-up:before {
  content: "";
}

.icon-baby:before {
  content: "";
}

.icon-calendar:before {
  content: "";
}

.icon-card:before {
  content: "";
}

.icon-check:before {
  content: "";
}

.icon-child:before {
  content: "";
}

.icon-clipboard:before {
  content: "";
}

.icon-clock:before {
  content: "";
}

.icon-coathanger:before {
  content: "";
}

.icon-drops:before {
  content: "";
}

.icon-elevator:before {
  content: "";
}

.icon-ellipsis:before {
  content: "";
}

.icon-entrance:before {
  content: "";
}

.icon-event:before {
  content: "";
}

.icon-exhibit:before {
  content: "";
}

.icon-facebook:before {
  content: "";
}

.icon-family:before {
  content: "";
}

.icon-film:before {
  content: "";
}

.icon-game:before {
  content: "";
}

.icon-handicap:before {
  content: "";
}

.icon-hands-on:before {
  content: "";
}

.icon-hourglass:before {
  content: "";
}

.icon-i-circle:before {
  content: "";
}

.icon-instagram:before {
  content: "";
}

.icon-list-bullet:before {
  content: "";
}

.icon-lock:before {
  content: "";
}

.icon-lost-and-found:before {
  content: "";
}

.icon-mail:before {
  content: "";
}

.icon-map-pin:before {
  content: "";
}

.icon-member:before {
  content: "";
}

.icon-minus-bold:before {
  content: "";
}

.icon-minus:before {
  content: "";
}

.icon-nurse:before {
  content: "";
}

.icon-paper:before {
  content: "";
}

.icon-play:before {
  content: "";
}

.icon-quote-close:before {
  content: "";
}

.icon-quote-open:before {
  content: "";
}

.icon-quote-pen:before {
  content: "";
}

.icon-refresh:before {
  content: "";
}

.icon-restroom:before {
  content: "";
}

.icon-ribbon:before {
  content: "";
}

.icon-search:before {
  content: "";
}

.icon-see-all:before {
  content: "";
}

.icon-segway:before {
  content: "";
}

.icon-share:before {
  content: "";
}

.icon-stairs:before {
  content: "";
}

.icon-stroller:before {
  content: "";
}

.icon-ticket:before {
  content: "";
}

.icon-tour:before {
  content: "";
}

.icon-trash:before {
  content: "";
}

.icon-tripadvisor:before {
  content: "";
}

.icon-twitter:before {
  content: "";
}

.icon-video:before {
  content: "";
}

.icon-wheelchair:before {
  content: "";
}

.icon-youtube:before {
  content: "";
}

@font-face {
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: 400;
  src: url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/ProximaNova/2FEDD3_2_0.eot");
  src: url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/ProximaNova/2FEDD3_2_0.eot?#iefix") format("embedded-opentype"),url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/ProximaNova/2FEDD3_2_0.woff2") format("woff2"),url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/ProximaNova/2FEDD3_2_0.woff") format("woff"),url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/ProximaNova/2FEDD3_2_0.ttf") format("truetype");
}

@font-face {
  font-family: "proxima-nova";
  font-style: italic;
  font-weight: 400;
  src: url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/ProximaNova/2FEDD3_3_0.eot");
  src: url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/ProximaNova/2FEDD3_3_0.eot?#iefix") format("embedded-opentype"),url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/ProximaNova/2FEDD3_3_0.woff2") format("woff2"),url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/ProximaNova/2FEDD3_3_0.woff") format("woff"),url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/ProximaNova/2FEDD3_3_0.ttf") format("truetype");
}

@font-face {
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: 600;
  src: url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/ProximaNova/2FEDD3_4_0.eot");
  src: url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/ProximaNova/2FEDD3_4_0.eot?#iefix") format("embedded-opentype"),url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/ProximaNova/2FEDD3_4_0.woff2") format("woff2"),url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/ProximaNova/2FEDD3_4_0.woff") format("woff"),url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/ProximaNova/2FEDD3_4_0.ttf") format("truetype");
}

@font-face {
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: 700;
  src: url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/ProximaNova/2FEDD3_0_0.eot");
  src: url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/ProximaNova/2FEDD3_0_0.eot?#iefix") format("embedded-opentype"),url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/ProximaNova/2FEDD3_0_0.woff2") format("woff2"),url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/ProximaNova/2FEDD3_0_0.woff") format("woff"),url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/ProximaNova/2FEDD3_0_0.ttf") format("truetype");
}

@font-face {
  font-family: "proxima-nova";
  font-style: italic;
  font-weight: 700;
  src: url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/ProximaNova/2FEDD3_1_0.eot");
  src: url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/ProximaNova/2FEDD3_1_0.eot?#iefix") format("embedded-opentype"),url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/ProximaNova/2FEDD3_1_0.woff2") format("woff2"),url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/ProximaNova/2FEDD3_1_0.woff") format("woff"),url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/ProximaNova/2FEDD3_1_0.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  src: url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/PlayfairDisplay/PlayfairDisplay-Regular.eot");
  src: local("Playfair Display"),local("PlayfairDisplay-Regular"),url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/PlayfairDisplay/PlayfairDisplay-Regular.eot?#iefix") format("embedded-opentype"),url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/PlayfairDisplay/PlayfairDisplay-Regular.woff") format("woff"),url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/PlayfairDisplay/PlayfairDisplay-Regular.ttf") format("truetype");
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2212,U+2215,U+E0FF,U+EFFD,U+F000;
  font-display: swap;
}

@font-face {
  font-family: "Playfair Display";
  font-style: italic;
  font-weight: 400;
  src: url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/PlayfairDisplay/PlayfairDisplay-Italic.eot");
  src: local("Playfair Display Italic"),local("PlayfairDisplay-Italic"),url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/PlayfairDisplay/PlayfairDisplay-Italic.eot?#iefix") format("embedded-opentype"),url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/PlayfairDisplay/PlayfairDisplay-Italic.woff") format("woff"),url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/PlayfairDisplay/PlayfairDisplay-Italic.ttf") format("truetype");
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2212,U+2215,U+E0FF,U+EFFD,U+F000;
}

@font-face {
  font-family: "icons";
  src: url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/icons.eot");
  src: url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/icons.eot?#iefix") format("embedded-opentype"),url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/icons.woff") format("woff"),url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/icons.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

