	// ======================================================================
	// FONTS
	// ======================================================================


	// ======================================================================
	// Typekit Loading
	// ======================================================================

	// @todo-ron need to make sure I know what this is for and what position should it be in if it matters.
/*
.wf-loading {
	h1, h2, h3, h4, h5, li, p  {
		opacity: 0;
	}
}

h1, h2, h3, h4, h5, li, p  {
	transition: opacity 0.1s ease-in-out;
}
*/

/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/2fedd3");

@font-face {
	font-family: 'proxima-nova';
	font-style: normal;
	font-weight: 400;
	src: font-url('ProximaNova/2FEDD3_2_0.eot');
	src: font-url('ProximaNova/2FEDD3_2_0.eot?#iefix') format('embedded-opentype'), font-url('ProximaNova/2FEDD3_2_0.woff2') format('woff2'), font-url('ProximaNova/2FEDD3_2_0.woff') format('woff'), font-url('ProximaNova/2FEDD3_2_0.ttf') format('truetype');
}

@font-face {
	font-family: 'proxima-nova';
	font-style: italic;
	font-weight: 400;
	src: font-url('ProximaNova/2FEDD3_3_0.eot');
	src: font-url('ProximaNova/2FEDD3_3_0.eot?#iefix') format('embedded-opentype'), font-url('ProximaNova/2FEDD3_3_0.woff2') format('woff2'), font-url('ProximaNova/2FEDD3_3_0.woff') format('woff'), font-url('ProximaNova/2FEDD3_3_0.ttf') format('truetype');
}

@font-face {
	font-family: 'proxima-nova';
	font-style: normal;
	font-weight: 600;
	src: font-url('ProximaNova/2FEDD3_4_0.eot');
	src: font-url('ProximaNova/2FEDD3_4_0.eot?#iefix') format('embedded-opentype'), font-url('ProximaNova/2FEDD3_4_0.woff2') format('woff2'), font-url('ProximaNova/2FEDD3_4_0.woff') format('woff'), font-url('ProximaNova/2FEDD3_4_0.ttf') format('truetype');
}

@font-face {
	font-family: 'proxima-nova';
	font-style: normal;
	font-weight: 700;
	src: font-url('ProximaNova/2FEDD3_0_0.eot');
	src: font-url('ProximaNova/2FEDD3_0_0.eot?#iefix') format('embedded-opentype'), font-url('ProximaNova/2FEDD3_0_0.woff2') format('woff2'), font-url('ProximaNova/2FEDD3_0_0.woff') format('woff'), font-url('ProximaNova/2FEDD3_0_0.ttf') format('truetype');
}

@font-face {
	font-family: 'proxima-nova';
	font-style: italic;
	font-weight: 700;
	src: font-url('ProximaNova/2FEDD3_1_0.eot');
	src: font-url('ProximaNova/2FEDD3_1_0.eot?#iefix') format('embedded-opentype'), font-url('ProximaNova/2FEDD3_1_0.woff2') format('woff2'), font-url('ProximaNova/2FEDD3_1_0.woff') format('woff'), font-url('ProximaNova/2FEDD3_1_0.ttf') format('truetype');
	font-display: swap;
}


	// Playfair Display :: Latin Only
	// @todo Eventually include woff2
@font-face {
	font-family: 'Playfair Display';
	font-style: normal;
	font-weight: 400;
	src: font-url('PlayfairDisplay/PlayfairDisplay-Regular.eot');
	src: local('Playfair Display'), local('PlayfairDisplay-Regular'), font-url('PlayfairDisplay/PlayfairDisplay-Regular.eot?#iefix') format('embedded-opentype'), font-url('PlayfairDisplay/PlayfairDisplay-Regular.woff') format('woff'), font-url('PlayfairDisplay/PlayfairDisplay-Regular.ttf') format('truetype');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
	font-display: swap;
}

// @todo Eventually include woff2
@font-face {
	font-family: 'Playfair Display';
	font-style: italic;
	font-weight: 400;
	src: font-url('PlayfairDisplay/PlayfairDisplay-Italic.eot');
	src: local('Playfair Display Italic'), local('PlayfairDisplay-Italic'), font-url('PlayfairDisplay/PlayfairDisplay-Italic.eot?#iefix') format('embedded-opentype'), font-url('PlayfairDisplay/PlayfairDisplay-Italic.woff') format('woff'), font-url('PlayfairDisplay/PlayfairDisplay-Italic.ttf') format('truetype');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
	font-family: "icons";
	src: font-url("icons.eot");
	src: font-url("icons.eot?#iefix") format("embedded-opentype"),
		 font-url("icons.woff") format("woff"),
		 font-url("icons.ttf") format("truetype");
	font-weight:normal;
	font-style:normal;
}
